import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./workflow/auth-guard.service";

const routes: Routes = [
	{
		path: "auth",
		loadChildren:
			"./workflow/account/account-workflow.module#AccountWorkflowModule"
	},
	{
		path: "home",
		loadChildren:
			"./workflow/dashboard/dashboard-workflow.module#DashboardWorkflowModule",
		canActivate: [AuthGuard]
	},
	{ path: "**", redirectTo: "auth" }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	providers: [AuthGuard],
	exports: [RouterModule]
})
export class AppRoutingModule {}
