import { EnvironmentModel } from "./environment.model";

export const NDP_ENVIRONMENT: EnvironmentModel = {
	development: "https://spdev.360salesolutions.com",
	test: "https://spdev.360salesolutions.com",
	production: "https://spdev.360salesolutions.com"
};

export const NDP_DEMO_ENVIRONMENT: EnvironmentModel = {
	development: "https://demo.service.360salesolutions.com",
	test: "https://demo.service.360salesolutions.com",
	production: "https://demo.service.360salesolutions.com"
};

export const GPC_ENVIRONMENT: EnvironmentModel = {
	development: "https://spdev.360salesolutions.com",
	test: "https://spdev.360salesolutions.com",
	production: "https://spdev.360salesolutions.com"
};

export const GAVILON_ENVIRONMENT: EnvironmentModel = {
	development: "https://serviceto.macrosource.com",
	test: "https://serviceto.macrosource.com",
	production: "https://serviceto.macrosource.com"
};
