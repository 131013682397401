import { DeploymentEnvironment } from "./configs/deployment.environment";
import { EnvironmentConfiguration } from "./configs/env.config";
import { Hosts } from "./configs/hosts";
import { getServiceHost } from "./configs/service.host";

const SERVER_ADDRESS = getServiceHost(Hosts.GAVILON, DeploymentEnvironment.PRODUCTION);
const LOCALHOST_ADDRESS = "http://127.0.0.1";
const DEFAULT_PORT = "8660";

let SERVICE_SECURITY_ENGINE = "secengine";
let SERVICE_SECURITY_OPERATION = "secoperation";
let SERVICE_ADMIN_OPERATION = "admoperation";
let SERVICE_PROCESS_OPERATION = "process-operation";
let SERVICE_SYNC_ENGINE = "sync-engine";
let SERVICE_TAKE_ORDER_ENGINE = "takeorder-engine";
let SERVICE_TAKE_ORDER_OPERATION = "takeorder-operation";

export const environment = {
	production: false,
	security_engine: assembleURL({
		service: SERVICE_SECURITY_ENGINE
	}),
	security_operation: assembleURL({
		service: SERVICE_SECURITY_OPERATION
	}),
	admin_operation: assembleURL({ service: SERVICE_ADMIN_OPERATION }),
	process_operation: assembleURL({ service: SERVICE_PROCESS_OPERATION }),
	sync_engine: assembleURL({ service: SERVICE_SYNC_ENGINE }),
	take_order_engine: assembleURL({ service: SERVICE_TAKE_ORDER_ENGINE }),
	take_order_operation: assembleURL({ service: SERVICE_TAKE_ORDER_OPERATION })
};

function assembleURL(config: EnvironmentConfiguration): string {
	let { service, port, local } = config;

	let address: string = local
		? `${LOCALHOST_ADDRESS}:${port ? port : DEFAULT_PORT}`
		: SERVER_ADDRESS;

	return `${address}/${service}/`;
}
