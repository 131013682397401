import {
	GAVILON_ENVIRONMENT,
	GPC_ENVIRONMENT,
	NDP_DEMO_ENVIRONMENT,
	NDP_ENVIRONMENT
} from "./environments";

export const Hosts = {
	NDP: NDP_ENVIRONMENT,
	NDP_DEMO: NDP_DEMO_ENVIRONMENT,
	GPC: GPC_ENVIRONMENT,
	GAVILON: GAVILON_ENVIRONMENT
};
