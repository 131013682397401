import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material";

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserAnimationsModule, AppRoutingModule, HttpClientModule],
	providers: [
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: MAT_DATE_LOCALE, useValue: "es-PE" }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
